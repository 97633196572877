<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí mục khảo sát</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogSurvey"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm khảo sát</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tiêu đề</th>
                    <th scope="col">Description</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(survey, i) in surveys">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ survey.name }}
                        </p>
                      </td>
                      <td>
                        <div
                          style="
                            max-width: max-content;
                            max-height: 300px;
                            overflow: hidden;
                          "
                          v-html="survey.description"
                        ></div>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editSurvey(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'QuestionSurvey',
                                  params: { id: survey.id },
                                }"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-comment-question-outline</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Danh sách câu hỏi</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="archiveSurvey(survey.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Archive</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!surveys.length">
                    <tr>
                      <td colspan="5" style="text-align: center">No Data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <SurveyForm
      :show_dialog="dialogSurvey"
      :type_form="type_form"
      :survey="survey"
      @setDialog="(e) => (this.dialogSurvey = e)"
      @resetDataSurvey="getAllSurvey()"
    ></SurveyForm>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import SurveyForm from "@/view/pages/survey2/modal/SurveyForm";
import Swal from "sweetalert2";
export default {
  name: "SurveyList",
  components: {
    SurveyForm,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      surveys: [],
      survey: {},
      dialogSurvey: false,
      type_form: null,
    };
  },
  created() {
    this.getAllSurvey();
  },
  methods: {
    async getAllSurvey() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/survey");
        if (res.status === 200) {
          vm.surveys = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogSurvey() {
      this.type_form = "add";
      this.dialogSurvey = true;
    },
    editSurvey(index) {
      this.type_form = "update";
      this.survey = this.surveys[index];
      this.dialogSurvey = true;
    },
    archiveSurvey(survey_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/survey/" + survey_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllSurvey();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
