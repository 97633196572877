<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog v-model="dialogSurvey" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add Survey</span
              >
              <span class="headline" v-else>Edit Survey</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSurvey = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Tên khảo sát:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Mô tả:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <Editor v-model="description_input"></Editor>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditSurvey(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditSurvey(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogSurvey = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import Editor from "@/components/ckeditor5/Editor";
import ApiService from "@/service/api.service";
export default {
  name: "SurveyForm",
  components: {
    Editor,
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    survey: {},
  },
  data() {
    return {
      is_call_api: false,
      survey_id: null,
      name_input: "",
      description_input: "",
    };
  },
  computed: {
    dialogSurvey: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogSurvey(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  // created() {
  //   this.setDataForm();
  // },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = "";
        this.description_input = "";
      } else {
        this.survey_id = this.survey.id;
        this.name_input = this.survey.name;
        this.description_input =
          this.survey.description === null ? "" : this.survey.description;
      }
    },
    validateData() {
      let flat = true;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập tên khảo sát !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnAddOrEditSurvey(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        name: this.name_input,
      };
      if (this.description_input !== null && this.description_input !== "")
        data.description = this.description_input;
      vm.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/survey", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataSurvey");
              vm.is_call_api = false;
              vm.dialogSurvey = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put("prep-app/survey/" + vm.survey_id, data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataSurvey");
              vm.is_call_api = false;
              vm.dialogSurvey = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
